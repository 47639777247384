import { API_URL } from "./consts";
import axios from "axios";
import { getTokenCookie } from "./common/cookie";

const setTokenHeader = () => {
    axios.defaults.headers.common["Authorization"] = getTokenCookie();
};

export const setSelectCover = async (formData) => {
    setTokenHeader();
    const response = await axios.post(`${API_URL}/user/select-cover`, formData, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") }
    });
    return response.data.data;
};

export const getBooksCoversAll = async (lang, clientId) => {
    setTokenHeader();
    const response = await axios.get(`${API_URL}/user/templates?language=${lang}&clientId=${clientId}`, {});
    return response.data.data;
};

export const getBookCategories = async () => {
    setTokenHeader();
    const response = await axios.get(`${API_URL}/user/categories`, {});
    return response.data.data;
};

export const uploadFileV4 = async (data) => {
    const response = await axios.post(`${API_URL}/frame/upload-face-v4`, data);
    return response.data;
};

export const removePrebookApi = async (prebookId) => {
    setTokenHeader();
    const response = await axios.delete(`${API_URL}/user/recent-prebooks/${prebookId}/remove`);
    return response.data;
};

export const getParams = async () => {
    setTokenHeader();
    const response = await axios.get(`${API_URL}/user/params`, {});
    return response.data.data;
};

export const initBookApiV2 = async (
    endUserEmail,
    clientId,
    faceImageUrl,
    croppedFaceImageUrl,
    endUserPhone,
    eyeglasses,
    uniqueId
) => {
    setTokenHeader();
    const response = await axios.post(`${API_URL}/user/initBook-v2`, {
        endUserEmail,
        endUserPhone,
        clientId,
        eyeglasses,
        userUniqueId: uniqueId
    });
    return response.data;
};

export const createBook = async (data) => {
    setTokenHeader();
    const response = await axios.post(`${API_URL}/user/startBook`, data);
    return response.data.data;
};

export const sendOtp = async (data) => {
    const response = await axios.post(`${API_URL}/auth/otp`, data);
    return response.data.data;
};

export const verifyOtp = async (data) => {
    const response = await axios.post(`${API_URL}/auth/otp-verify`, data);
    return response.data.data;
};

export const getConfig = async (lang, clientId) => {
    const response = await axios.get(`${API_URL}/frame/config-v2?lang=${lang}&clientId=${clientId}`);
    return response.data.data;
};

export const getPrebooks = async () => {
    setTokenHeader();
    const response = await axios.post(`${API_URL}/user/recent-prebooks-v2`, {});
    return response.data.data;
};
