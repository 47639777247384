import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import { getRelevantTemplateImage, getRelevantTemplateTitle } from "../../common/utils";
import PlotModal from "./PlotModal";
import { trackMixEvent } from "../../common/eventManager";

const Wrapper = styled.div``;

const TitleWrapperStyled = styled.div`
    background: rgba(000, 000, 000, 0.4);
    height: 70px;
    position: absolute;
    bottom: 0;
    width: 100%;
    display: grid;
    align-items: center;

    .title {
        color: #fff;
        font-size: 24px;
        z-index: 2;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
        width: 100%;
        white-space: break-spaces;
    }
`;

const ItemsWrapper = styled.div`
    max-width: 100%;
    padding: 1%;
    display: block;
    overflow-x: auto;
    white-space: nowrap;
`;

const ItemStyled = styled.div`
    display: inline-block;
    margin: 0 7px;
    width: 270px;
    height: 270px;
    position: relative;
    border-radius: 28px;
    overflow: hidden;
    border: 4px solid transparent;

    img {
        //border-radius: 8px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &.selected {
        border: 4px solid #ffc43d;
    }

    .info {
        //top: 14px;
        //right: 34px;
    }
    .info-wrapper {
        position: absolute;
        top: 10px;
        right: 14px;
        background: #fff;
        border-radius: 20px;
        width: 43px;
        height: 25px;
        z-index: 1;
    }
    .arrow-dz {
        position: absolute;
        top: 9px;
        right: 8px;
        z-index: 1;

        &::after {
            content: "";
            position: absolute;
            width: 9px;
            height: 1px;
            transform: rotate(47deg);
            background: rgb(255, 196, 61);
        }

        &::before {
            content: "";
            width: 9px;
            height: 1px;
            transform: rotate(135deg);
            position: absolute;
            top: 6px;
            background: rgb(255, 196, 61);
        }
    }
`;

const MobileSlider = ({ selectedItem, items, lang, langData, progress, selectedTemplate, getItemAnimation }) => {
    const [openSelection, setOpened] = useState(false);
    const [closeModalForce, s_closeModalForce] = useState(false);
    const [selectedPlotItem, s_selectedPlotItem] = useState(null);

    const divRefs = useRef([]);

    useEffect(() => {
        if (selectedItem) {
            const selectedItemIndex = items.findIndex((item) => item._id === selectedItem);
            if (divRefs.current[selectedItemIndex]) {
                divRefs.current[selectedItemIndex].scrollIntoView({
                    behavior: "smooth",
                    block: "nearest",
                    inline: "center"
                });
            }
        }
    }, [selectedItem, items]);

    const openSelectionFunc = () => {
        setOpened(!openSelection);
        s_closeModalForce(false);
    };

    return (
        <Wrapper>
            <ItemsWrapper>
                {items.map((item, itemIndex) => {
                    const title = getRelevantTemplateTitle(item, progress);
                    const imageToShow = getRelevantTemplateImage(item, progress);

                    const isSelected = selectedItem === item._id;
                    return (
                        <ItemStyled
                            ref={(el) => (divRefs.current[itemIndex] = el)}
                            tabIndex={isSelected ? "0" : "-1"}
                            onClick={() => selectedTemplate(item)}
                            key={item._id}
                            className={isSelected ? "selected poikop" : "poikop"}
                        >
                            <div
                                onKeyDown={(e) => {
                                    if (e.key === " " || e.key === "Enter") {
                                        s_selectedPlotItem(item);
                                        openSelectionFunc(e);
                                    }
                                }}
                                tabIndex={isSelected ? "0" : "-1"}
                                onClick={(event) => {
                                    trackMixEvent("template_info", "open", progress.bookId);
                                    openSelectionFunc();
                                    s_selectedPlotItem(item);
                                }}
                                className="info-wrapper"
                                aria-label={`${langData.arias.more_info_about_template} ${title}`}
                            >
                                <div className="info" />
                                <div className="arrow-dz" />
                            </div>
                            <motion.div {...getItemAnimation(0.2 * itemIndex, false)}>
                                {isSelected ? <div className="selectedItem"></div> : ""}
                                <img src={imageToShow} className="template-img" alt="template" />
                                <TitleWrapperStyled>
                                    <div className="title">{title}</div>
                                </TitleWrapperStyled>
                            </motion.div>
                        </ItemStyled>
                    );
                })}
            </ItemsWrapper>

            {openSelection && (
                <PlotModal
                    progress={progress}
                    selectedPlotItem={selectedPlotItem}
                    openSelectionFunc={openSelectionFunc}
                    lang={lang}
                    closeModalForce={closeModalForce}
                    ariaTitle={langData.arias.templateInfoDialogTitle}
                />
            )}
        </Wrapper>
    );
};

export default MobileSlider;
