import mixpanel from "mixpanel-browser";

export function trackEvent(eventName) {
    try {
        window?.gtag("event", eventName, {
            event_category: "frame",
            event_label: eventName
        });
    } catch (e) {
        console.log(e);
    }
    try {
        window?.fbq("track", eventName, {
            content_name: "frame"
        });
    } catch (e) {
        console.log(e);
    }
    try {
        window.dataLayer = window?.dataLayer || [];
        window.dataLayer.push({
            event: eventName,
            eventCategory: "frame",
            eventLabel: eventName
        });
        console.log("dataLayer", window.dataLayer);
    } catch (e) {
        console.log(e);
    }
}

export function identifyUser(phone) {
    try {
        mixpanel.identify(phone);

        mixpanel.people.set({
            $phone: phone
        });
    } catch (e) {
        console.log(e);
    }
}

const onlyOnceEventsForMixpanel = [
    "hero_details_first_open",
    "otp_first_open",
    "template_page_first_open",
    "frame_first_open"
];

export function trackMixEvent(eventName, subEvent, bookId = "", message = "", clientId = "") {
    try {
        const isOnlyOnceEvent = onlyOnceEventsForMixpanel.includes(eventName);
        const isEventInSession = sessionStorage.getItem(eventName);
        if (isOnlyOnceEvent && isEventInSession) {
            return;
        }

        mixpanel.track(eventName, {
            subEvent: subEvent,
            bookId: bookId,
            message: message,
            clientId: clientId
        });
        if (isOnlyOnceEvent) {
            sessionStorage.setItem(eventName, "1");
        }
    } catch (e) {
        console.log(e);
    }
}
