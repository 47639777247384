import React, { useState, useEffect, useRef } from "react";
import { uploadFileV4 } from "../../../ApiCalls";
import info from "../../images/info.svg";
import { motion } from "framer-motion";
import Modal from "../../../common/Modal";
import Button from "./../Button";
import { Tooltip } from "react-tooltip";
import { AlertRoleStyled } from "./../Stage2";
import Loader from "./../Loader";
import CONSTS from "../../../consts";
import UploadImageError from "./../uploadImage/UploadImageError";
import styled from "styled-components";
import UploadImagesDescriptionV2 from "./../uploadImage/UploadImagesDescriptionV2";
import { Cropper } from "react-mobile-cropper";
import { trackEvent, trackMixEvent } from "../../../common/eventManager";
import { STAGES_STEPS } from "../../../common/dataMaps";
import { getTokenCookie } from "../../../common/cookie";
import { ReactComponent as PenIcon } from "../../images/pen-icon.svg";
import { ReactComponent as RightArrowIcon } from "../../images/right-arrow-icon.svg";
import { ReactComponent as SecureIcon } from "../../images/secure-icon.svg";
import { getMobileQuery } from "../../../common/utils";
import LeftSidePanel from "../leftSidePanel/LeftSidePanel";

const UploadContainerWrapperStyled = styled.div`
    margin: 0;

    .uploader.poilz {
        margin: 60px auto 20px auto;
    }

    .uploadbox {
        height: fit-content;
    }

    @media (max-width: 768px) {
        .uploadbox {
            height: 40px;
        }
    }

    @media (max-height: 650px) {
        h1.headline {
            margin: 10px 0 5px;
        }
    }
`;

const UploadPhotoWrapperStyled = styled.div`
    width: 80%;
    margin-left: 20%;
    margin-bottom: 5px;

    h1 {
        font-size: clamp(24px, 6vw, 64px);
        margin-bottom: 18px;

        @media (min-height: 900px) {
            margin-top: 60px;
        }
    }

    .secure-message {
        height: auto;
        margin-bottom: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 15px;
        svg {
            margin: ${({ isHeLang }) => (isHeLang ? "0 0 0 10px" : "0 10px 0 0 ")};
            width: 24px;
            height: 24px;
            flex-shrink: 0;
            align-self: center;
        }

        .message-text {
            font-size: 14px;

            @media (max-width: 768px) {
                font-size: 12px;
            }
        }
    }

    @media (max-width: 768px) {
        margin-left: 0;
        width: 100%;
        position: relative;

        h1 {
            position: absolute;
            top: 0;
            font-size: 22px;
            width: 100%;
            text-align: center;
            font-weight: 600;
        }
    }
`;

const UploaderWrapperStyled = styled.div`
    margin: 14px auto 12px;
    display: block;
    grid-gap: 0;

    .uploader {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        max-height: 260px;
        min-height: 140px;
        height: 21vh;
        max-width: 560px;
        margin-bottom: 20px;
        border: 1px dashed #00000050;
        border-radius: 30px;
        padding-top: 18px;
        padding-bottom: 18px;

        .error {
            margin: 0;
        }

        @media (max-width: 1000px) {
            padding: 34px 0 34px;
        }

        @media (min-height: 780px) {
            height: 30vh;
            width: 48vw;
        }

        h3 {
            font-size: 38px;
            font-weight: 400;
            margin: 0 0 14px 0;

            @media (max-width: 460px) {
                display: none;
            }
        }

        @media (max-width: 768px) {
            margin-top: calc(5vh + 20px);
            border: none;
            padding: 0;
            height: auto;
            min-height: 50px;

            @media (max-height: 660px) {
                margin-top: 4vh;
            }

            p {
                display: none;
            }
        }

        @media (max-width: 400px) {
            margin-top: calc(134px - 16vh);
        }

        @media (max-height: 660px) {
            padding-top: 8px;
            padding-bottom: 8px;
        }
    }
`;

const NextButtonWrapper = styled.div`
    .next-button {
        height: 78px;
        color: #fff;
        margin-bottom: 1vh;
        border-radius: 22px;

        @media (max-width: 1000px) {
            height: 62px;
        }

        @media (max-width: 768px) {
            width: 80%;
            margin-bottom: 10px;
            margin-top: 10px;

            border-radius: 16px;
            .next-button-title {
                .button-text {
                    font-size: 34px;
                }
            }
        }

        @media (max-width: 400px) {
            margin-top: 0;
        }

        @media (max-height: 650px) {
            height: 56px;
            font-size: 14px;
        }
    }

    .next-button-title {
        margin: 0 18px;
        padding-top: 4px;

        display: flex;
        align-items: baseline;

        .button-text {
            font-weight: 400;
            font-size: 46px;
            margin: 12px;

            @media (max-height: 650px) {
                margin: 0 12px;
                font-size: 36px;
            }
        }

        .rotate-icon {
            transform: rotate(180deg);
        }

        svg {
            width: 28px;
            height: 28px;

            @media (max-height: 650px) {
                width: 20px;
                height: 20px;
            }
        }
    }
`;

const UploaderContentStyled = styled.div`
    .image-content {
        display: flex;
        font-weight: 400;
        align-items: center;

        svg {
            fill: none;
            width: 18px;
            height: 18px;
            margin: ${({ isHeLang }) => (isHeLang ? "0 0 0 10px" : "0 10px 0 0 ")};
            padding-bottom: 4px;
        }
        .upload-title {
            line-height: 1;
        }
    }

    .upload-button {
        height: 48px;
        weight: 400;
        background: linear-gradient(90deg, #f79520 0%, #fbae39 100%);
        transition:
            opacity 0.3s ease,
            transform 0.3s ease;
        color: #fff;
        margin-bottom: 30px;
        padding: 28px 26px;
        font-size: 30px;
        border-radius: 12px;

        @media (max-width: 768px) {
            height: 48px;
            padding: 26px;
            font-size: 20px;
            border-radius: 30px;
            margin-bottom: 10px;
        }
    }

    .upload-subtitle {
        color: #4d4d4d;
        font-size: 18px;
        margin-bottom: 0;
    }

    .or {
        margin: 20px;
        position: relative;
        background: #fff;

        @media (max-width: 460px) {
            margin: 11px;
        }

        p {
            margin: 0 auto;
            background: #fff;
            width: fit-content;
            padding: 0 6px;
            z-index: 2;
            position: relative;
        }

        .hr {
            position: absolute;
            top: -9px;
            border-top: 1px solid #d3cece;
            left: 10%;
            right: 10%;
            @media (max-width: 460px) {
                top: -7px;
            }
        }
    }
`;

const CropperStyled = styled.div`
    max-width: 660px;
    width: 50%;
    height: 490px;
    margin: 10px auto;
    padding: 0 0 210px;

    @media (max-width: 661px) {
        width: 100%;
        height: 301px;
    }

    @media (max-height: 650px) {
        height: 289px;
    }

    @media (min-height: 650px) {
        height: calc(100vh - 373px);
        overflow-y: hidden;
    }

    @media (max-width: 768px) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background-color: #fff;
        z-index: 100;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0;
        padding: 0;
        max-width: 100%;

        .cropper {
            max-width: 90%;
            width: auto;
            height: auto;
        }

        .next-button {
            position: relative;
            margin-top: 10px;
        }
    }

    @media (max-height: 660px) {
        padding: 0 0 180px;

        .cropper.rmc-cropper-wrapper {
            padding-top: 10px;
        }
    }
`;

const CropperButtonStyled = styled.div`
    margin-top: 15px;

    .text-description-cropper {
        font-size: 14px;
    }
`;

function UploadPhotoStage({
    lang,
    langData,
    loading,
    setLoading,
    updateProgress,
    getItemAnimation,
    getItemAnimationAnlim,
    s_currentStage,
    imageBlob,
    setImageBlob,
    clientId
}) {
    const imageInputRef = useRef(null);
    const fileInputRef = useRef(null);
    const dnaRef = useRef(null);
    const exampleModalRef = useRef(null);
    const cropperRef = useRef(null);

    const [error, setError] = useState("");
    const [errorCode, setErrorCode] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [openSelection, setOpened] = useState(false);
    const [onImageFocus, s_onImageFocus] = useState(0);

    const [ariaAlert, s_ariaAlert] = useState("");

    const [showStage, s_showStage] = useState("REGULAR");

    const [fileDataStage, s_fileDataStage] = useState(null);

    const [, s_blobBuf] = useState(null);
    const [uploadButtonClicked, setUploadButtonClicked] = useState(false);

    const innerWidth = getMobileQuery();
    const isMobile = innerWidth < 768;
    const images = CONSTS.preview_images;

    useEffect(() => {
        trackMixEvent("frame_first_open", "", "", "", clientId);
        trackEvent("frame_upload_image_stage");
    }, [clientId]);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 1500);

        return () => clearInterval(interval);
    }, [images.length]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (onImageFocus) {
                switch (event.key) {
                    case " ":
                    case "Enter":
                        imageInputRef.current.click();
                        break;
                    default:
                        break;
                }
            }
        };

        const divElement = imageInputRef.current;

        if (divElement) {
            divElement.addEventListener("keydown", handleKeyDown);
        }

        return () => {
            if (divElement) {
                divElement.removeEventListener("keydown", handleKeyDown);
            }
        };
    }, [onImageFocus]);

    const handleDragEnter = (e) => {
        e.preventDefault();
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.dataTransfer.dropEffect = "copy";
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        handleFiles(files);
    };

    const handleFiles = async (files) => {
        const file = files[0];
        if (file) {
            await handleCropStage(file);
        }
    };

    const changeUpload = async (event) => {
        trackMixEvent("upload_image_button_click", "click", "", "", clientId);
        s_ariaAlert(langData.arias.image_load);
        setUploadButtonClicked(true);
        setError("");
        if (!event.target.files[0]) {
            return false;
        }
        const file = event.target.files[0];
        if (file) {
            await handleCropStage(file);
        }
    };

    const handleCropStage = async (file) => {
        const blob = new Blob([file], { type: file.type });
        s_fileDataStage(URL.createObjectURL(blob));
        s_blobBuf(blob.slice());
        s_showStage("CROP");
    };

    const resizeFile = async (file) => {
        setLoading("imageUploading");
        setTimeout(() => {
            if (dnaRef.current) {
                dnaRef.current.focus();
            }
        }, 0);

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
            const img = new Image();
            img.src = e.target.result;
            img.onload = () => {
                const maxWidth = 1000;
                const maxHeight = 1000;

                let newWidth = img.width;
                let newHeight = img.height;

                if (img.width > maxWidth || img.height > maxHeight) {
                    const aspectRatio = img.width / img.height;

                    if (aspectRatio > 1) {
                        newWidth = maxWidth;
                        newHeight = maxWidth / aspectRatio;
                    } else {
                        newWidth = maxHeight * aspectRatio;
                        newHeight = maxHeight;
                    }
                }

                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");
                canvas.width = newWidth;
                canvas.height = newHeight;

                ctx.drawImage(img, 0, 0, newWidth, newHeight);

                canvas.toBlob((blog) => {
                    uploadImage(blog);
                }, file.type);
            };
        };
    };

    const uploadImage = async (file, isBlob) => {
        const formData = new FormData();

        let blob = file;
        if (!isBlob) {
            blob = new Blob([file], { type: file.type });
        }

        formData.append("file", blob);

        uploadFileV4(formData)
            .then((data) => {
                if (data.success) {
                    updateProgress("eyeglasses", data?.data?.eyeglasses);
                    updateProgress("boundingBox", data?.data?.boundingBox, true);
                    updateProgress("faceDataId", data?.data?.faceDataId);
                    const reader = new FileReader();

                    reader.onloadend = function () {
                        const base64data = reader.result;
                        setImageBlob(base64data);
                        localStorage.setItem("imageBlob", base64data);
                        setLoading(false);
                        s_ariaAlert("");
                        setTimeout(() => {
                            if (imageInputRef?.current) {
                                imageInputRef.current.focus();
                            }
                        }, 0);
                    };

                    reader.readAsDataURL(blob);
                } else {
                    const errorMsg = langData.image_errors[data.errorCode];

                    const isValidError = CONSTS.valid_errors.indexOf(data.errorCode) !== -1;
                    if (isValidError) {
                        setErrorCode(data.errorCode);
                        s_showStage("ERROR_IMAGE");
                        trackEvent("uploaded_image_not_good");
                        trackMixEvent("upload_image_failed", "fail", "", "validationError", clientId);
                    }

                    updateProgress("faceImageUrl", "");
                    updateProgress("croppedFaceImageUrl", "");
                    s_ariaAlert(errorMsg);
                    setError(errorMsg);
                    setLoading(false);
                }
                trackEvent("image_upload");
                trackMixEvent("upload_image_success", "success", "", "", clientId);
            })
            .catch((e) => {
                updateProgress("faceImageUrl", "");
                updateProgress("croppedFaceImageUrl", "");
                s_ariaAlert(langData.error.many_faces);
                setError("Error while uploading image");
                setLoading(false);
                trackMixEvent("upload_image_failed", "fail", "", e.message, clientId);
                console.log("error", e);
            });
    };

    const openSelectionFunc = () => {
        setOpened(!openSelection);
        document.body.classList.remove("modal-active");
    };

    const nextStage = (event) => {
        if (!imageBlob) {
            event.preventDefault();
            setError(langData.error.error_upload);
            return false;
        }
        const token = getTokenCookie();
        trackMixEvent("upload_image_button_click_next_general", "click", "", "", clientId);
        if (uploadButtonClicked) {
            trackMixEvent("upload_image_button_click_next_general_image_uploaded", "click", "", "", clientId);
        }
        if (token) {
            trackMixEvent("upload_image_button_click", "click", "", "hero_details", clientId);
            s_currentStage(STAGES_STEPS.INFO);
        } else {
            trackMixEvent("upload_image_button_click", "click", "", "otp", clientId);
            s_currentStage(STAGES_STEPS.OTP);
        }
    };

    const handleUploadClick = (e) => {
        e.preventDefault();
        fileInputRef.current.click();
    };

    let uploadButtonText = langData.upload_photo_stage.upload_photo;
    if (imageBlob && !isMobile) {
        uploadButtonText = langData.upload_photo_stage.replace_photo;
    }
    if (isMobile) {
        uploadButtonText = langData.upload_photo_stage.upload;
    }
    if (imageBlob && isMobile) {
        uploadButtonText = langData.upload_photo_stage.replace_photo;
    }

    return (
        <UploadPhotoWrapperStyled isHeLang={lang === "he"}>
            <LeftSidePanel
                lang={lang}
                langData={langData}
                handleUploadClick={handleUploadClick}
                imageBlob={imageBlob}
            />
            <UploadContainerWrapperStyled>
                {ariaAlert && <AlertRoleStyled role="alert">{ariaAlert}</AlertRoleStyled>}
                <h1 className="headline">{langData.upload_photo_stage.title}</h1>
                {showStage === "ERROR_IMAGE" && (
                    <div className="uploader poilz">
                        <UploadImageError
                            errorCode={errorCode}
                            langData={langData}
                            onClick={() => {
                                setError("");
                                setErrorCode(null);
                                s_showStage("REGULAR");
                                trackEvent("try_again_upload_image");
                            }}
                        />
                    </div>
                )}
                {showStage === "REGULAR" && (
                    <>
                        <UploaderWrapperStyled>
                            <div
                                onDragEnter={handleDragEnter}
                                onDragOver={handleDragOver}
                                onDragLeave={handleDragLeave}
                                onDrop={handleDrop}
                                className="uploader"
                            >
                                <form className="upload">
                                    <label>
                                        {loading === "imageUploading" && (
                                            <div aria-label={langData.arias.image_load} ref={dnaRef}>
                                                <Loader ariaLabel={langData.arias.image_load} />
                                            </div>
                                        )}

                                        {loading !== "imageUploading" && (
                                            <div
                                                onFocus={() => {
                                                    s_onImageFocus(1);
                                                }}
                                                onBlur={() => {
                                                    s_onImageFocus(0);
                                                }}
                                                ref={imageInputRef}
                                                role="button"
                                                tabIndex="0"
                                                aria-label={
                                                    !imageBlob
                                                        ? langData.arias.image_to_load
                                                        : langData.arias.loaded_image
                                                }
                                                className={`${"uploadbox"}`}
                                            >
                                                <motion.div {...getItemAnimation(0.4)} className="image">
                                                    <UploaderContentStyled isHeLang={lang === "he"}>
                                                        <Button
                                                            onClick={(e) => handleUploadClick(e)}
                                                            className="upload-button"
                                                        >
                                                            <div className="image-content">
                                                                <PenIcon />
                                                                <div className="upload-title">{uploadButtonText}</div>
                                                            </div>
                                                        </Button>
                                                        <p className="upload-subtitle">
                                                            {langData.upload_photo_stage.image_desc}
                                                        </p>
                                                    </UploaderContentStyled>
                                                </motion.div>
                                            </div>
                                        )}
                                        <input
                                            ref={fileInputRef}
                                            type="file"
                                            accept="image/jpg, image/png, image/jpeg"
                                            onChange={changeUpload}
                                        />
                                    </label>
                                    <div className="error">{error}</div>
                                </form>
                            </div>
                            <UploadImagesDescriptionV2 lang={lang} langData={langData} />
                        </UploaderWrapperStyled>

                        {openSelection && (
                            <Modal
                                clickRef={exampleModalRef}
                                role="alertdialog"
                                isOpen={true}
                                onClose={openSelectionFunc}
                                lang={lang}
                                backGroundClose={true}
                                ariaTitle={langData.arias.exampleDialogTitle}
                            >
                                <div className="popup_container">
                                    <ul className="bullet_list">
                                        <li>
                                            <img src={info} alt="תמונה של פנים דוגמה מספר 1" />
                                            <span>{langData.stage1.popup_bullets.bullet_one}</span>
                                        </li>
                                        <li>
                                            <img src={info} alt="תמונה של פנים דוגמה מספר 2" />
                                            <span>{langData.stage1.popup_bullets.bullet_two}</span>
                                        </li>
                                        <li>
                                            <img src={info} alt="תמונה של פנים דוגמה מספר 3" />
                                            <span>{langData.stage1.popup_bullets.bullet_three}</span>
                                        </li>
                                    </ul>
                                    <div className="image-slider">
                                        {images.map((image, index) => (
                                            <img
                                                key={index}
                                                src={image}
                                                alt={`slide ${index}`}
                                                className={`slider-image ${index === currentIndex ? "visible" : ""}`}
                                            />
                                        ))}
                                    </div>
                                </div>
                                <Button
                                    tab={"0"}
                                    className="understandBtn"
                                    onClick={() => {
                                        openSelectionFunc();
                                    }}
                                >
                                    {langData.stage1.popup_exit}
                                </Button>
                            </Modal>
                        )}

                        <NextButtonWrapper>
                            <Tooltip id="upload-image-please" className="pizeda" />
                            <Button
                                dataTooltipId={!imageBlob ? "upload-image-please" : null}
                                dataTooltipContent={!imageBlob ? langData.please_upload_image : null}
                                onClick={nextStage}
                                className="next-button"
                                disabled={!imageBlob}
                            >
                                <div className="next-button-title">
                                    <div className="button-text">{langData.general.next}</div>
                                    {!isMobile && (
                                        <RightArrowIcon className={`${lang === "he" ? "rotate-icon" : ""}`} />
                                    )}
                                </div>
                            </Button>
                        </NextButtonWrapper>
                    </>
                )}

                {showStage === "CROP" && (
                    <CropperStyled className="cropper-wrapper-p">
                        <Cropper
                            stencilProps={{
                                aspectRatio: {
                                    minimum: 1,
                                    maximum: 1
                                }
                            }}
                            sizeRestrictions={{ minWidth: 100, minHeight: 100, maxWidth: null, maxHeight: null }}
                            ref={cropperRef}
                            src={fileDataStage}
                            onReady={(e) => {
                                console.log("re");
                            }}
                            onChange={(e) => {
                                console.log("p");
                            }}
                            className={"cropper"}
                        />
                        <CropperButtonStyled>
                            <p className="text-description-cropper">{langData.upload_photo_stage.cropper_text}</p>
                            <Button
                                onClick={() => {
                                    const canvas = cropperRef.current.getCanvas();

                                    if (canvas) {
                                        canvas.toBlob((blob) => {
                                            if (blob) {
                                                resizeFile(blob);
                                            }
                                        });
                                    }
                                    s_showStage("REGULAR");
                                }}
                                className="next-button"
                            >
                                {langData.general.next}
                            </Button>
                        </CropperButtonStyled>
                    </CropperStyled>
                )}
                <div className="secure-message">
                    <SecureIcon />
                    <div className="message-text">
                        {langData.upload_photo_stage.secure_message.part_one}&nbsp;
                        <b>{langData.upload_photo_stage.secure_message.part_two}</b>&nbsp;
                        {langData.upload_photo_stage.secure_message.part_three}
                    </div>
                </div>
            </UploadContainerWrapperStyled>
        </UploadPhotoWrapperStyled>
    );
}

export default UploadPhotoStage;
