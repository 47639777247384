import React from "react";
import { PhoneInput } from "react-international-phone";
import styled from "styled-components";
import { PhoneNumberUtil } from "google-libphonenumber";

const PhoneStyledWrapper = styled.div`
    .react-international-phone-input-container {
        direction: ltr;
    }

    .react-international-phone-input {
        width: 100%;
        height: 39px;
        font-size: 20px;
    }

    .react-international-phone-country-selector {
        //margin-inline-end: 15px;
        height: 39px;
    }

    .react-international-phone-country-selector-button {
        height: 39px;
    }

    &.error-phone-input {
        .react-international-phone-input,
        .react-international-phone-country-selector-button {
            border-color: #ff0000ff;
        }
    }

    button:disabled,
    button[disabled] {
        background: #fff;
    }
`;

const phoneUtil = PhoneNumberUtil.getInstance();

const PhoneInputComponent = ({
    s_isPhoneError,
    isPhoneTouched,
    countries,
    config,
    isPhoneError,
    progress,
    s_isPhoneTouched,
    updateProgress
}) => {
    return (
        <PhoneStyledWrapper className={isPhoneError ? "error-phone-input" : ""}>
            <PhoneInput
                key={progress?.phone}
                onFocus={() => {
                    s_isPhoneTouched(true);
                }}
                defaultCountry={config.countryCode || "il"}
                hideDropdown={true}
                countries={countries}
                showDisabledDialCodeAndPrefix={true}
                autoFocus={true}
                value={progress?.phone}
                onBlur={() => {
                    try {
                        if (isPhoneTouched) {
                            const parsedPhone = phoneUtil.parseAndKeepRawInput(progress?.phone);
                            const isValid = phoneUtil.isValidNumber(parsedPhone);
                            const nationalNumber = parsedPhone.getNationalNumber().toString();
                            const numberLength = nationalNumber.length;
                            const isValidLength =
                                (config.countryCode === "il" && numberLength > 8) || config.countryCode !== "il";
                            if (isValid && isValidLength) {
                                s_isPhoneError(false);
                            } else {
                                s_isPhoneError(true);
                            }
                        }
                    } catch (e) {
                        s_isPhoneError(true); // Always set error on parsing failure
                    }
                }}
                onChange={(phone, meta) => {
                    try {
                        if (isPhoneTouched) {
                            const parsedPhone = phoneUtil.parseAndKeepRawInput(phone);
                            const isValid = phoneUtil.isValidNumber(parsedPhone);
                            const nationalNumber = parsedPhone.getNationalNumber().toString();
                            const numberLength = nationalNumber.length;
                            const isValidLength =
                                (config.countryCode === "il" && numberLength > 8) || config.countryCode !== "il";
                            if (isValid && isValidLength) {
                                s_isPhoneError(false);
                            }
                        }
                    } catch (e) {}
                    if (config.countryCode === "il") {
                        if (phone.startsWith("+9720")) {
                            phone = phone.replace("+9720", "+972");
                        }
                        if (phone.startsWith("+0")) {
                            phone = phone.replace("+0", "+972");
                        }

                        if (phone === "") {
                            phone = "+972";
                        }
                    }
                    if (config.countryCode === "us") {
                        if (phone === "") {
                            phone = "+1";
                        }
                    }

                    if (config.countryCode === "dk") {
                        if (phone === "") {
                            phone = "+45";
                        }
                    }

                    updateProgress("phone", phone);
                }}
            />
        </PhoneStyledWrapper>
    );
};

export default PhoneInputComponent;
