import styled, { keyframes } from "styled-components";
import { motion } from "framer-motion";

const placeHolderPump = keyframes`
    from {
        opacity: 0.2;
    }

    to {
        opacity: 0.8;
    }
`;

export const VerticalLineStyled = styled.div`
    height: 200px;
    width: 1px;
    background: rgba(0, 0, 0, 0.12);

    &.vertical-line-stage2 {
        margin-top: 112px;
        margin-inline-start: 9px;
        margin-inline-end: 33px;
    }

    @media (max-width: 1000px) {
        display: none;
    }
`;

export const CommonWrapperStyled = styled.div`
    max-width: 1371px;
    max-height: 814px;
    margin: 39px auto 10px;
    border-radius: 22px;
    //border: 1px solid rgba(86, 77, 77, 0.08);
    //background: #FFF;
    //box-shadow: 1px 2px 3px 0 rgba(22, 18, 8, 0.20);

    @media (max-width: 1000px) {
        margin: 49px 1% 0;
        max-height: none;
    }

    @media (max-width: 1000px) {
        margin: 15px 1% 0;
        max-height: none;
    }

    &.stage2 {
        .next-button {
            margin: 51px auto 0;

            @media (max-width: 480px) {
                margin: 40px auto 42px;
            }
        }
    }

    &.stage3-wrapper {
        position: relative;

        .next-button {
            margin: 106px auto 10px;

            @media (max-width: 480px) {
                margin: 45px auto 10px;
            }
        }
    }

    &.stage4-wrapper {
        max-height: inherit;
        max-width: 1051px;
        margin: 1px auto 10px;

        @media (max-width: 480px) {
            margin: 21px 1% 0;
        }

        .next-button {
            margin: 35px auto 5px;

            .wand-icon {
                width: 22px;
            }

            @media (max-width: 480px) {
                margin: 25px auto 42px;
            }
        }

        .choose-cover {
            margin: 0 auto;
            //position: absolute;
        }
    }
`;

export const ButtonStyled = styled(motion.button)`
    background: #ffa927;
    color: #000;
    font-weight: bold;
    border-radius: 8px;
    padding: 0 25px;
    display: flex;
    align-items: center;
    width: fit-content;
    border: none;
    cursor: pointer;
    line-height: 1.4;
    transition: all 0.35s;
    position: relative;
    outline: none;

    &:focus {
        outline: 1px solid transparent;
    }

    &:disabled {
        background: #B3B3B3;
    }

    &[disabled] {
        background: #B3B3B3;
    }

    &.disabled {
        background: #cfcbc4;
    }

    &.disabled:hover {
        cursor: default;
        background: #cfcbc4;
    }

    &:disabled:hover {
        cursor: default;
        background: #cfcbc4;
    }

    &:hover {
        background: #ffc43d;
    }

    &.next-button {
        justify-content: center;
        margin: 0 auto 0;
        padding: 6px 32px;
        font-size: 25px;
        display: flex;
        align-items: center;
        padding: 10px 20px
        justify-content: center;
    }

    &.upload-button {
        justify-content: center;
        margin: 0 auto 0;
        padding: 1px 42px;
        font-size: 24px;
    }

    &.personalise_btn {
        padding: 3px 17px;

        span {
            display: grid;
            grid-template-columns: auto auto;
            align-items: center;
        }
    }

    //&:after {
    //    position: absolute;
    //    content: "";
    //    top: 0;
    //    left: 0;
    //    width: 0;
    //    height: 100%;
    //    background: #FFC43D;
    //    transition: all .35s;
    //    border-radius: 8px;
    //}

    span {
        position: relative;
        z-index: 2;
    }

    &:hover:after {
        width: 100%;
    }

    &:disabled:hover:after {
        width: 0;
    }

    &.loading {
        pointer-events: none;

        &:after {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            -webkit-animation: loadingSpinner 850ms cubic-bezier(0.7, 1.05, 0.78, 0.78) infinite;
            animation: loadingSpinner 850ms cubic-bezier(0.7, 1.05, 0.78, 0.78) infinite;
            border: 2px solid #fff;
            border-radius: 100%;
            border-top: 2px solid transparent;
            height: 16px;
            width: 16px;
            will-change: transform;
            z-index: 2;
            background: #ffa927;
        }

        span {
            color: #ffa927;
            opacity: 0;
        }
    }

    &.not_clickable {
        pointer-events: none;
    }
`;

export const ButtonFocusAnimationStyled = styled(motion.button)`
    background: #ffa927;
    color: #000;
    font-weight: bold;
    border-radius: 8px;
    padding: 0 25px;
    display: flex;
    align-items: center;
    width: fit-content;
    border: none;
    cursor: pointer;
    line-height: 1.4;
    position: relative;

    &:disabled {
        background: #cfcbc4;
    }

    &[disabled] {
        background: #cfcbc4;
    }

    &:disabled:hover {
        cursor: default;
        background: #cfcbc4;
    }

    &:hover {
        background: #ffc43d;
    }

    &.next-button {
        justify-content: center;
        margin: 56px auto 0;
        padding: 6px 32px;
        font-size: 25px;
    }

    &:before {
        content: "";
        //background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
        //background: linear-gradient(45deg, rgba(2,0,36,1) 0%, rgba(255,169,39,1) 35%, rgba(0,212,255,1) 100%);
        background: linear-gradient(45deg, #ffc43d, #bdbb2b, #7bae32, #2c9d43, #008854, #007362);
        position: absolute;
        top: -2px;
        left: -2px;
        background-size: 400%;
        z-index: -1;
        filter: blur(5px);
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        animation: glowing 20s linear infinite;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        border-radius: 10px;
    }

    &:active {
        color: #000;
    }

    &:active:after {
        background: transparent;
    }

    &:focus:before {
        opacity: 1;
    }

    &:after {
        z-index: -1;
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: #111;
        left: 0;
        top: 0;
        border-radius: 10px;
    }

    @keyframes glowing {
        0% {
            background-position: 0 0;
        }
        50% {
            background-position: 400% 0;
        }
        100% {
            background-position: 0 0;
        }
    }
`;

export const PumpLoaderStyled = styled.div`
    background: #bfbfbf;
    animation: ${placeHolderPump} 1s alternate infinite cubic-bezier(0.37, 0, 0.63, 1);
`;
