import React, { useEffect, useRef } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
    //display: flex;
    display: grid;
    justify-content: center;
    margin-top: 16px;
    margin-bottom: 25px;
    grid-gap: 5px;
    grid-template-columns: repeat(auto-fit, 65px);

    @media (min-width: 750px) {
        margin-top: 6px;
        margin-bottom: 10px;
    }

    @media (max-width: 480px) {
        max-width: 100%;
        padding: 1%;
        display: block;
        overflow-x: auto;
        white-space: nowrap; /* Prevent items from wrapping to the next line */
        grid-template-columns: unset;
    }
`;

const CatItemStyled = styled.div`
    position: relative;
    cursor: pointer;

    @media (max-width: 480px) {
        display: inline-block;
        margin: 0 3px;
    }

    img {
        width: 65px;
        height: 65px;
        border-radius: 50%;
        border: 2px solid transparent;

        @media (min-width: 750px) {
            width: 35px;
            height: 35px;
        }
        
        @media (max-width: 480px) {
            width: 55px;
            height: 55px;
        }
    }

    &:hover {
        img {
            border: 2px solid #ffc43d;
        }

        text-decoration: underline;
    }

    &.sel {
        img {
            border: 2px solid #ffc43d;
        }

        text-decoration: underline;
    }
`;

const CatItemTitleStyled = styled.div`
    font-size: 15px;
    position: relative;
    top: -8px;
`;

const ICON_MAP = {
    all: {
        icon: "https://cdn.mebook.ai/static/frame/categories/%D7%9E%D7%99%D7%91%D7%95%D7%A7%20%D7%94%D7%9B%D7%9C.png?width=150"
    },
    "654a0e60b9a82077967ee9e7": {
        icon: "https://cdn.mebook.ai/static/frame/categories/%D7%A4%D7%A0%D7%98%D7%96%D7%99%D7%94.png?width=150"
    },
    "64e46f73e90457b7c4db52b6": {
        icon: "https://cdn.mebook.ai/static/frame/categories/%D7%A1%D7%A4%D7%95%D7%A8%D7%98.png?width=150"
    },
    "64e1b706c6d6a697e8bf7b52": {
        icon: "https://cdn.mebook.ai/static/frame/categories/%D7%97%D7%92%D7%99%D7%9D.png?width=150"
    },
    "64cab0c84af1fd3e9af52a3d": {
        icon: "https://cdn.mebook.ai/static/frame/categories/%D7%97%D7%99%D7%95%D7%AA%20%D7%9E%D7%99%D7%91%D7%95%D7%A7.png?width=150"
    },
    "64ca8bc424bf167cc346a965": {
        icon: "https://cdn.mebook.ai/static/frame/categories/%D7%94%D7%A8%D7%A4%D7%AA%D7%A7%D7%90%D7%95%D7%AA%20%D7%9E%D7%99%D7%91%D7%95%D7%A7.png?width=150"
    }
};

// https://print.mebook.ai/static/templates/64c7e79dab45e67e6a143830/female.png

const FansyCategories = ({ cats, getCurrentLangItem, currentCat, changeByIndexHandle, catLen }) => {
    const ref = useRef(null);

    useEffect(() => {
        if (ref.current) {
            ref.current.focus();
        }
    }, [currentCat]);

    return (
        <Wrapper>
            {cats.map((caties, idx) => {
                const icon = ICON_MAP[caties._id]?.icon;
                const isSelected = currentCat === caties._id;

                return (
                    <CatItemStyled
                        role="tab"
                        aria-selected={isSelected}
                        aria-label={getCurrentLangItem(caties)}
                        tabIndex={isSelected ? "0" : "-1"}
                        ref={isSelected ? ref : null}
                        className={isSelected ? "sel" : ""}
                        key={caties._id}
                        onClick={() => changeByIndexHandle(idx)}
                        onKeyDown={(e) => {
                            if (e.key === " " || e.key === "Enter") {
                                changeByIndexHandle(idx);
                            }
                            if (e.key === "ArrowLeft") {
                                if (idx < catLen - 1) {
                                    changeByIndexHandle(idx + 1);
                                }
                            }
                            if (e.key === "ArrowRight") {
                                if (idx > 0) {
                                    changeByIndexHandle(idx - 1);
                                }
                            }
                            if (e.key === "Home") {
                                changeByIndexHandle(0);
                            }
                            if (e.key === "End") {
                                changeByIndexHandle(catLen - 1);
                            }
                        }}
                    >
                        <img src={icon} alt={getCurrentLangItem(caties)} />
                        <CatItemTitleStyled>{getCurrentLangItem(caties)}</CatItemTitleStyled>
                    </CatItemStyled>
                );
            })}
        </Wrapper>
    );
};

export default FansyCategories;
