import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Modal from "../../common/Modal";
import { ReactComponent as TrashIcon } from "../images/trash-icon.svg";
import { EDITOR_URL } from "../../consts";
import { MainContext } from "../../ContextState";

const WrapperStyled = styled.div`
    position: absolute;
    margin: 10px auto;
    bottom: -61px;
    width: 100%;
`;

const HaChupchikStyled = styled.div`
    cursor: pointer;
`;

const ContainerStyled = styled.div``;

const TitleStyled = styled.div`
    font-size: 14px;
`;

const BooksStyled = styled.div`
    &:hover {
        .me-pook {
            margin-inline-start: -7px;
            transition: margin-inline-start 0.5s ease;
        }
    }
`;

const BookStyled = styled.div`
    display: inline-block;
    vertical-align: top;
    margin-inline-start: -10px;
    transition: margin-inline-start 0.5s ease;

    img {
        width: 25px;
        border-radius: 50%;
        border: 3px solid #fff;
    }
`;

const ModalListStyled = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
    padding: 15px;
    overflow: auto;
    height: 70dvh;
    max-height: 530px;
    position: relative;

    @media (max-width: 1130px) {
        width: 100%;
    }

    @media (max-width: 460px) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
        padding: 10px;
        padding: 0 10px;
        width: 100%;
        box-sizing: border-box;

        a {
            width: 100%;
            display: grid;

            img {
                width: 100%;
            }
        }
    }
`;

const ModalItemStyled = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 10px;
    box-sizing: border-box;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ModalItemTitleStyled = styled.div``;

const ModalItemContStyled = styled.div`
    position: relative;

    .main-image-prebook {
        width: 150px;

        @media (max-width: 460px) {
            width: 100%;
            height: auto;
            border-radius: 4px;
            object-fit: cover;
        }
    }
`;

const ModalItemContTitleStyled = styled.div`
    margin-bottom: 9px;

    h3,
    h4 {
        padding: 0;
        margin: 5px 0;
        font-size: 20px;
    }

    h4 {
        font-size: 18px;
    }

    hr {
        width: 70%;
    }
`;

const PopUpStyled = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    z-index: 10;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    .title {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    div {
        display: flex;
        justify-content: center;
        gap: 15px;

        div {
            padding: 8px 16px;
            color: white;
            border-radius: 4px;
            cursor: pointer;
            font-weight: bold;
            transition: background-color 0.2s;

            &:hover {
                background-color: #e5921d;
            }

            @media (max-width: 460px) {
                padding: 3px 6px;
            }
        }

        .confirm-btn {
            background-color: #ffa927;
        }

        .cancel-btn {
            background-color: #7b7b7b;

            &:hover {
                background-color: #5a6268;
            }
        }
    }
`;

const RemoveStyled = styled.div`
    position: absolute;
    right: 4px;
    top: 4px;
    background: #ffa927;
    border-radius: 50%;
    width: 30px;
    height: 30px;

    svg {
        width: 20px;
        height: 20px;
        margin: 4px;
    }

    @media (max-width: 460px) {
        right: 4px;
        top: 4px;
        display: grid;
        align-items: center;
        justify-items: center;
        width: 20px;
        height: 20px;

        svg {
            width: 14px;
            height: 14px;
            margin: 3px;
        }
    }
`;

const RecentPrebooks = ({ items, lang, langData, removePrebook }) => {
    const exampleModalRef = useRef(null);
    const [openSelection, setOpened] = useState(false);
    const [currentItemId, setCurrentItemId] = useState(null);

    const { clientIdContext, configContext } = useContext(MainContext);
    const [clientId] = clientIdContext;
    const [config] = configContext;

    const openSelectionFunc = () => {
        setOpened(!openSelection);
        document.body.classList.remove("modal-active");
    };

    useEffect(() => {
        setCurrentItemId(null);
    }, []);

    const items6 = items.slice(0, 6);

    const handleRemoveClick = (e, itemId) => {
        e.preventDefault();
        e.stopPropagation();
        setCurrentItemId(itemId);
    };

    const handleConfirm = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (currentItemId) {
            removePrebook(currentItemId);
        }
        setCurrentItemId(null);
    };

    const handleCancel = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setCurrentItemId(null);
    };

    return (
        <WrapperStyled>
            <HaChupchikStyled>
                <ContainerStyled onClick={openSelectionFunc}>
                    <TitleStyled>{langData.past_books}</TitleStyled>
                    <BooksStyled>
                        {items6.map((item) => {
                            const image = item?.bookPagesData?.[0]?.images?.[0];

                            return (
                                <BookStyled className={"me-pook"} key={item._id}>
                                    <img src={`${image}?width=150`} alt="" />
                                </BookStyled>
                            );
                        })}
                    </BooksStyled>
                </ContainerStyled>
            </HaChupchikStyled>

            {openSelection && (
                <Modal
                    clickRef={exampleModalRef}
                    role="alertdialog"
                    isOpen={true}
                    onClose={openSelectionFunc}
                    lang={lang}
                    backGroundClose={true}
                    ariaTitle={langData.arias.exampleDialogTitle}
                >
                    <div className="popup_container preebooks-modal">
                        <ModalItemContTitleStyled>
                            <h3>{langData.preebook_title}</h3>
                            <h4>{langData.preebook_subTitle}</h4>
                            <hr />
                        </ModalItemContTitleStyled>
                        <ModalListStyled>
                            {items.map((item) => {
                                const image = item?.bookPagesData?.[0]?.images?.[0];
                                const lang = item.template?.languageKey || "he";

                                let editorUrl = `https://editor.mebook.ai`;
                                if (config.editorUrl) {
                                    editorUrl = `${config.editorUrl}`;
                                }

                                return (
                                    <ModalItemStyled key={item._id}>
                                        <ModalItemTitleStyled>{item.title}</ModalItemTitleStyled>
                                        <ModalItemContStyled>
                                            <RemoveStyled onClick={(e) => handleRemoveClick(e, item._id)}>
                                                <TrashIcon fill="#fff" alt="Remove" />
                                            </RemoveStyled>
                                            <a
                                                href={`${editorUrl}?bookId=${item._id}&token=null&language=${lang}&clientId=${clientId}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <img src={`${image}?width=150`} alt="" className="main-image-prebook" />
                                            </a>
                                            {currentItemId && currentItemId === item._id && (
                                                <PopUpStyled>
                                                    <div className="title"> {langData.stage2.are_you_sure}</div>
                                                    <div>
                                                        <div className="cancel-btn" onClick={(e) => handleCancel(e)}>
                                                            {langData.stage2.no}
                                                        </div>
                                                        <div className="confirm-btn" onClick={(e) => handleConfirm(e)}>
                                                            {langData.stage2.yes}
                                                        </div>
                                                    </div>
                                                </PopUpStyled>
                                            )}
                                        </ModalItemContStyled>
                                    </ModalItemStyled>
                                );
                            })}
                        </ModalListStyled>
                    </div>
                </Modal>
            )}
        </WrapperStyled>
    );
};

export default RecentPrebooks;
